/* About.css */

.about-container {
    height: 100%; /* Adjust this value based on your header height */
    background-color: #f8f8f8;
    text-align: center;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    z-index: -10;
}

.about-container .our-company {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    overflow: hidden;

}

.about-container .our-company .about-hero {
    width: 100%;
    height: 650px;
}

.about-container .our-company img {
    width: 120%;
    height: auto;
    object-fit: cover;
}

.about-container .our-company .our-company-txt {
    position: absolute;
    display: flex;
    height: 150px;
    background-color: #f8f8f8;
    top: 600px;
}
.about-container .our-company .our-company-txt h2 {
    font-size: 3rem;
    font-weight: 700;
    padding: 20px;
}

.about-container .about-body {
    padding: 0px 80px 0px 80px;
    margin-top: 80px;
}

.about-container .about-body p {
    text-align: left;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 30px;
    
}


@media (max-width: 768px) {

    .about-container .our-company .about-hero {
        width: 100%;
        height: 30vh;
    }

.about-container .our-company .our-company-txt {
    position: absolute;
    display: flex;
    height: 50px;
    background-color: #f8f8f8;
    top: 253px;
    align-items: center;
}
.about-container .our-company .our-company-txt h2 {
    font-size: 1.1rem;
    font-weight: 700;
    padding-left: 30px;
}

.about-container .about-body {
    padding: 0px 20px 0px 20px;
    margin-top: 30px;
}

.about-container .about-body p {
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 30px;
}


.contact-container .contact-us {
    margin-top: 50px;
}


}
