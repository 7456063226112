/* Footer.css */

.footer-container {
    width: 100%;
    background-color: #ffffff;
    padding: 20px 0;
    text-align: center;
  }
  
  .company-info {
    font-size: 14px;
    color: #888;
  }
  
  .company-info p {
    margin: 5px 0;
  }
  

  .footer-container .company-info p a {
    color: #1a237e;
    font-weight: 700;
  }

  .footer-container .company-info p a:hover {
    color: #1a227eb3;
    font-weight: 700;
  }