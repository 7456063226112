/* Header.css */

.header {
    display: flex;
    align-items: center;
    height: 80px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px rgba(35,31,32,.2);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 40;
  }
  
  .logo {
    display: flex;
    align-items: center;
    margin-left: 40px;
    width: 350px;
    height: 40px;
    z-index: 10;
  }

  .logo span:first-child {
    font-weight: 700;
    font-size: 1.5rem;
    margin-right: 5px;
  }

  .logo span:nth-child(2) {
    font-weight: 500;
    font-size: 1.5rem;
  }

  .logo img {
    width: 40px;
    height: 100%;
  }
  
  .menu {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    list-style: none;
    font-weight: 700;
    padding: 0;
    
  }

  .menu-list {
    display: flex;
  align-items: center;
  justify-content: center;
  }

 .menu-list.show-menu {
  display: flex;
  justify-content: center;
 }

 .menu-list li a.active {
  padding-bottom: 28px;
  border-bottom: 4px solid #ffcc00; /* Adjust the color and style as needed */
}
  
  .menu li {
    display: inline-block;
    margin-left: 40px;
  }
  
  .menu li:first-child {
    margin-left: 0;
  }

  
  .menu li a {
    color: #1e1e1e;
    text-decoration: none;
  }

  .login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    background-color: #ffcc00;
    transition: background-color 0.3s;
    z-index: 10;
  }
  
  .login-button span:first-child {
    margin-right: 10px;
  }
  .login-button ul {
    padding: 0; /* Remove default margin */
  }


  .login-button ul, li, a {
    color: #1e1e1e;
    list-style: none;
    text-decoration: none;
  }

  .login-button:hover {
    background-color: rgba(255, 204, 0, 0.675); /* Change background color on hover */
  }

  .hamburger-icon {
    display: none; /* Hide hamburger icon by default */
  }
  .small-menu-list {
    display: none;
  }
  
  @media (max-width: 768px) {

    .logo {
      display: flex;
      align-items: center;
      margin-left: 15px;
      width: 200px;
      height: 40px;
      z-index: 10;
    }
  
    .logo span:first-child {
      font-weight: 700;
      font-size: 1rem;
      margin-right: 5px;
    }
  
    .logo span:nth-child(2) {
      font-weight: 500;
      font-size: 1rem;
    }

    .menu-list {
      display: none;
    }

    .menu li {
      display: block; 
      padding-bottom: 20px; 
      margin: 0;
      text-align: left;

    }

    .menu .login-button li {
      padding-bottom: 0px;
    }

    

    .small-menu-list {
      display: flex;
      justify-content: left;
      flex-direction: column;
      position: fixed;
      width: 80%;
      height: 100vh;
      left: -80%; /* Initially hide the menu off-screen */
      top: 80px;
      background-color: white; /* Adjust background color as needed */
      transition: left 0.3s ease; /* Add transition for smooth animation */
      z-index: 40;
      
    }
    .small-menu-list li a.active {
      padding-bottom: 10px;
      border-bottom: 4px solid #ffcc00; /* Adjust the color and style as needed */
    }
    
    .small-menu-list.show-menu {
      left: 0; /* Slide in the menu when showMenu is true */
    }
  
    .hamburger-icon {
      display: flex;
      align-items: center; 
      cursor: pointer;
      margin-left: 20px; 
      font-size: 24px;
    }
  }
  