.App {
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: CBABeaconSans,sans-serif;
  background-color: #f6f6f6;
  color: #1e1e1e;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
