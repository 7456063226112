/* Home.css */

.Home_container {
    position: absolute;
    top: 80px;
    width: 100%;
    background-color: #f4f4f4;
    text-align: center;
    display: flex;
    z-index: 10;
    overflow-x: hidden;
}

.rest {
    position: relative;
    width: 100%;
    background-color: #f4f4f4;
    text-align: center;
    z-index: 10;
}